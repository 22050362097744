<template>
    <div style="margin-top: 50px">
        <div class="container-bg mb-10" style="margin-top: 50px !important; width: 950px; margin: auto">
            <div class="container-fg pr-10 pl-10">
                <div style="display: flex; flex-direction: row">
                    <img style="width: 130px; margin-bottom: 1%" src="../../assets/logos/getac-logo.svg" />
                    <div class="text-h7 nav-font pl-3" style="margin-top: 13px; text-transform: uppercase"
                        >Parts Replace Manuals</div
                    >
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" label="Search" outlined dark dense hide-details></v-text-field>
                </div>
                <v-divider class="mt-2 mb-2" style="border-color: gray" />
                <div class="pa-5 pt-10 manuals-bg" style="display: block; margin: auto">
                    <v-data-iterator
                        :items="manuals"
                        :items-per-page.sync="itemsPerPage"
                        :page.sync="page"
                        dark
                        :search="search"
                    >
                        <template v-slot="props">
                            <v-row style="padding: 0px 35px">
                                <v-col v-for="(item, index) in props.items" :key="index" cols="12" sm="6" md="5" lg="4">
                                    <a
                                        :href="
                                            'https://rma.getac.com/MSS/downloadFileAction.do?REFERENCE_ID=' +
                                            item.REFERENCE_ID
                                        "
                                        style="text-decoration-line: unset !important"
                                    >
                                        <v-card class="mx-auto manuals-card" max-width="344" outlined>
                                            <v-list-item three-line>
                                                <v-list-item-content>
                                                    <v-list-item-title
                                                        class="text-h5 mb-1"
                                                        style="text-align: right; font-weight: 700"
                                                        >{{ item.REFERENCE_ID.replace('_SM_Manual', '') }}
                                                    </v-list-item-title>
                                                    <div class="text-overline" style="text-align: right">
                                                        Self-Maintain Manual
                                                    </div>
                                                    <v-icon
                                                        size="45"
                                                        class="pb-10 manual-icon"
                                                        style="position: absolute; opacity: 0.4"
                                                        >{{ mdiArrowDownBoldCircle }}</v-icon
                                                    >
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-card>
                                    </a>
                                </v-col>
                            </v-row>
                        </template>
                    </v-data-iterator>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mdiArrowDownBoldCircle } from '@mdi/js'
import gql from 'graphql-tag'
export default {
    name: 'SelfMaintainManual',
    data() {
        return {
            mdiArrowDownBoldCircle,
            manuals: [],
            page: 1,
            search: '',
            itemsPerPage: 12,
            itemsPerPageArray: [12, 48, 60]
        }
    },
    created() {
        this.loading = true
        this.$apollo
            .query({
                query: gql`
                    query queryURL($url: String!, $method: String) {
                        queryURL(url: $url, method: $method)
                    }
                `,
                variables: {
                    url: `https://rma.getac.com/MSS/commonReportAction.do?method=getReportJson&reportId=106&funcId=SM_MANUAL_LIST`,
                    method: 'GET'
                }
            })
            .then((data) => {
                this.loading = false
                const parsed = JSON.parse(data.data.queryURL)
                this.manuals = parsed.result
            })
            .finally(() => {
                this.loading = false
            })
    },
    methods: {
        openManual(id) {
            window.open('https://rma.getac.com/MSS/downloadFileAction.do?REFERENCE_ID=' + id)
        }
    }
}
</script>

<style scoped>
.manuals-card {
    cursor: pointer;
    background-color: #474747 !important;
    border-radius: 5px !important;
    padding-top: 15px;
    padding-bottom: 15px;
    overflow: hidden !important;
    text-align: center;
    transition: background-color 300ms;
}
.manuals-card:hover {
    background-color: #db5120 !important;
}
.manuals-bg {
    background-color: rgb(43, 43, 43);
    border-radius: 25px;
}
.manual-icon {
    color: #db5120;
    transition: background-color 600ms;
}
.manuals-card:hover .manual-icon {
    color: #ffffff;
}
</style>
